import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import userContext from "../../userContext";
import account from "../../../screens/account";
import form from "../../../screens/form";
import editVehicles from "../../../screens/editVehicles";
import editCreditVehicles from "../../../screens/editCreditVehicles";
import signin from "../../../screens/sign-in";
import order from "../../order";
import register from "../../../screens/register";
import newUser from "../../../screens/new-user";
import formReport from "../../../screens/formReport";
import supportCenter from "../../../screens/supportCenter";
import weightIncrease from "../../../screens/weightIncrease";
import thankYou from "../../../screens/thankYou";
import orderHistory from "../../../screens/orderHistory";
import forgotpin from "../../../screens/forgot-pin";
import home from "../../../screens/home";
import "bootstrap/dist/css/bootstrap.css";
import "@progress/kendo-theme-material/dist/all.css";
import enMessages from "../en.json";
import ErrorBoundary from "../../error/components/ErrorBoundary";
import { fetchTaxTable } from "../../taxYear/taxYear.actions";
import { ThankYouVinChangeScreenContainer as ThankYouVinChangeScreen,
  ThankYouScreenContainer as ThankYouScreen } from "screens/thankYou/components/ThankYouScreenContainer";
// import "font-awesome/css/font-awesome.css";

loadMessages(enMessages, "en-US");

const {
  actions: { fetchTaxYears: fetchTaxYearsCreator }
} = order;

const {
  components: { AccountScreen }
} = account;

const {
  components: { FormScreen }
} = form;

const {
  components: { FormReportScreenContainer: FormReportScreen }
} = formReport;

const {
  components: { WeightIncreaseScreenContainer: WeightIncreaseScreen }
} = weightIncrease;

const {
  components: {
    SupportCenterScreenContainer: SupportCenterScreen,
    ContactSupportFormScreen,
    IrsRejectedFormScreen,
    NoticeOrLetterFormScreen,
    ScheduleOneSupportFormScreen,
    BulkPricingFormScreen,
    VinCorrectionFormScreen,
    FileByPhoneFormScreen
  }
} = supportCenter;

const {
  components: { OrderHistoryScreenContainer: OrderHistoryScreen }
} = orderHistory;

const {
  components: { EditVehiclesScreen }
} = editVehicles;

const {
  components: { EditCreditVehiclesScreenContainer: EditCreditVehiclesScreen }
} = editCreditVehicles;

const { getUserContext } = userContext.selectors;
const { SignIn } = signin.components;
const { Register } = register.components;
const { ForgotPin } = forgotpin.components;
const { Home } = home.components;
const {
  components: { NewUserWizard }
} = newUser;

class App extends Component {
  componentDidMount() {
    const {
      props: { fetchTaxYears, userContext, fetchTaxTable }
    } = this;

    // Parse query string and store in local storage
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.toString()) {
      searchParams.forEach((value, key) => {
        localStorage.setItem(key, value);
      });
    }

    if (userContext.id) {
      fetchTaxYears();
      fetchTaxTable();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userContext.Id && this.props.userContext.Id) {
      this.props.fetchTaxYears();
      this.props.fetchTaxTable();
    }
  }

  render() {
    const { history } = this.props;

    return (
      <ConnectedRouter history={history}>
        <LocalizationProvider language="en-US">
          <div className="app">
            <ErrorBoundary>
              <Route exact path="/" component={Home} />
              <Route exact path="/register" component={Register} />
              <Route path="/sign-in" component={SignIn} />
              <Route path="/forgot-pin" component={ForgotPin} />
              <Route path="/wizard" component={NewUserWizard} />
              <Route path="/account" component={AccountScreen} />
              <Route path="/form" component={FormScreen} />
              <Route path="/form-report" component={FormReportScreen} />
              <Route path="/order-history" component={OrderHistoryScreen} />
              <Route path="/support-center" component={SupportCenterScreen} />
              {/* <Route path="/dashboard" component={DashboardScreen} />   */}
              <Route path="/contact" component={ContactSupportFormScreen} />
              <Route path="/weight-increase" component={WeightIncreaseScreen} />
              <Route
                path="/vin-correction"
                component={VinCorrectionFormScreen}
              />
              <Route path="/thank-you" component={ThankYouScreen} />
              <Route path="/thank-you-vin-change" component={ThankYouVinChangeScreen} />
              <Route path="/notice" component={NoticeOrLetterFormScreen} />
              <Route
                path="/schedule-1"
                component={ScheduleOneSupportFormScreen}
              />
              <Route path="/irs-rejected" component={IrsRejectedFormScreen} />
              <Route path="/bulk-pricing" component={BulkPricingFormScreen} />
              <Route path="/file-by-phone" component={FileByPhoneFormScreen} />
              <Route path="/edit-vehicles" component={EditVehiclesScreen} />
              <Route
                path="/edit-credits"
                component={EditCreditVehiclesScreen}
              />
            </ErrorBoundary>
          </div>
        </LocalizationProvider>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userContext: getUserContext(state)
});

const mapDispatchToProps = dispatch => ({
  fetchTaxYears: () => dispatch(fetchTaxYearsCreator()),
  fetchTaxTable: () => dispatch(fetchTaxTable())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
