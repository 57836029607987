import { destroy } from "redux-form";
import * as actionTypes from "./order.actionTypes";
import doAsync from "../doAsync";
import { cleanOrder, normalizeOrder, normalizeOrders } from "./order.utilities";
import { PAYMENT_METHOD_FORM_NAME } from "./order.constants";
import {
  CallGoogleAnalytics,
  toggleThankYouDialog
} from "../paymentModal/paymentModal.actions";

export const patchOrder = (id, order) =>
  doAsync({
    actionType: actionTypes.PATCH_ORDER_ASYNC,
    url: `orders/${id}`,
    httpConfig: {
      body: JSON.stringify(order),
      headers:{
        ['wait-lock-key']: '*'
      }
    },
    httpMethod: "put",
    mapResponseToPayload: r => normalizeOrder(cleanOrder(r))
  });

export const checkLastYearVehicles = async taxYear => {
  const response = await fetch("api/orders/previous-year-vehicle-count", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify({
      TaxYear: taxYear,
      DefaultOrderFromHistory: true
    })
  });

  return response.json();
};

export const postOrder = (taxYear, redirect = null, fromHistory = false) =>
  doAsync({
    actionType: actionTypes.POST_ORDER_ASYNC,
    url: "orders",
    httpConfig: {
      body: JSON.stringify({
        TaxYear: taxYear,
        DefaultOrderFromHistory: fromHistory
      }),
      headers:{
        ['wait-lock-key']: taxYear
      }
    },
    httpMethod: "post",
    mapResponseToPayload: r => ({
      ...normalizeOrder(cleanOrder(r)),
      redirect: redirect ? `${redirect}?order=${r.Id}` : null
    })
  });

export const postSendScheduleOneEmail = (formId, emails) =>
  doAsync({
    actionType: actionTypes.SEND_SCHEDULE_ONE_EMAIL_ASYNC,
    url: `schedule1/${formId}/email`,
    httpConfig: {
      body: JSON.stringify(emails),
      headers:{
        ['wait-lock-key']: formId
      }
    },
    httpMethod: "post",
    mapResponseToPayload: r => r,
  });

export const submitOrder = orderId => dispatch =>
  dispatch(
    doAsync({
      actionType: actionTypes.SUBMIT_ORDER,
      url: `orders/${orderId}/submit`,
      httpMethod: "post",
      httpConfig: {
        headers:{
          ['wait-lock-key']: '*'
        }
      },
      mapResponseToPayload: () => {
        CallGoogleAnalytics({
          event_category: "User",
          name: "Purchase",
          label: "New",
          value: 1
        });
        return {};
      },
      noBusySpinner: false
    })
  ).then(() => dispatch(destroy(PAYMENT_METHOD_FORM_NAME)));

export const fetchOrder = (id, shouldValidate = false) =>
  doAsync({
    actionType: actionTypes.FETCH_ORDER_ASYNC,
    url: `orders/${id}`,
    httpMethod: "get",
    mapResponseToPayload: r => ({
      ...normalizeOrder(cleanOrder(r)),
      shouldValidate
    }),
    noBusySpinner: false
  });

export const fetchTaxYears = () =>
  doAsync({
    actionType: actionTypes.FETCH_TAX_YEARS_ASYNC,
    url: "taxyears",
    httpMethod: "get",
    mapResponseToPayload: r => {
      const [currentTaxYear] = r.filter(x => x.CurrentTaxYear);
      const [heroTaxYear] = r.filter(x => x.ShowHero);
      return {
        currentTaxYear,
        heroTaxYear,
        taxYears: r
      };
    },
    noBusySpinner: false
  });

export const fetchScheduleOneUri = filename =>
  doAsync({
    actionType: actionTypes.FETCH_SCHEDULE_ONE_URI,
    url: `schedule1-test/${filename}`,
    httpMethod: "get",
    mapResponseToPayload: r => r,
    noBusySpinner: false
  });

export const fetchHistoryOrder = id =>
  doAsync({
    actionType: actionTypes.FETCH_HISTORY_ORDER_ASYNC,
    url: `orders-history/${id}`,
    httpMethod: "get",
    mapResponseToPayload: r => normalizeOrder(cleanOrder(r)),
    noBusySpinner: false
  });

export const sendReceiptEmail = (orderId, email) =>
  doAsync({
    actionType: actionTypes.SEND_RECEIPT_EMAIL,
    url: `orders/${orderId}/resend-receipt-email`,
    httpConfig: {
      body: JSON.stringify({ Email: email }),
      headers: {
        ['idempotency-key']: orderId
      }
    },
    httpMethod: "post",
    mapResponseToPayload: r => r,
    noBusySpinner: false
  });

export const fetchOrders = () => dispatch => {
  return dispatch(
    doAsync({
      actionType: actionTypes.FETCH_ORDERS_ASYNC,
      url: "orders-history",
      httpMethod: "get",
      mapResponseToPayload: r => {
        return {
          sort: r.map(x => x.Id),
          data: normalizeOrders(r.map(x => cleanOrder(x)))
        };
      },
      noBusySpinner: false
    })
  ).then(() => dispatch(fetchTaxYears()));
};

export const fetchActiveOrders = () =>
  doAsync({
    actionType: actionTypes.FETCH_ACTIVE_ORDERS_ASYNC,
    url: "orders",
    httpMethod: "get",
    mapResponseToPayload: r =>
      r.reduce((map, obj) => {
        map[obj.TaxYear] = obj.Id;
        return map;
      }, {}),
    noBusySpinner: false
  });

export const deleteVehicles = (orderId, isTaxable) =>
  doAsync({
    actionType: actionTypes.DELETE_VEHICLES_ASYNC,
    url: `orders/${orderId}/vehicles/${isTaxable ? "taxable" : "credit"}`,
    httpMethod: "delete",
    httpConfig: {
      headers: {
        Accept: "*/*",
        ['wait-lock-key']: orderId
      }
    },
    mapResponseToPayload: r => normalizeOrder(cleanOrder(r))
  });

export const togglePaymentMethodWidgetIsExpanded = () => ({
  type: actionTypes.TOGGLE_PAYMENT_METHOD_WIDGET_IS_EXPANDED
});

export const setPaymentMethodWidgetIsExpanded = isExpanded => ({
  type: actionTypes.SET_PAYMENT_METHOD_WIDGET_IS_EXPANDED,
  payload: isExpanded
});

export const setActiveReceiptModal = orderId => ({
  type: actionTypes.SET_ACTIVE_RECEIPT_MODAL,
  payload: orderId
});

export const toggleDisclaimerIsVisible = () => ({
  type: actionTypes.TOGGLE_DISCLAIMER_IS_VISIBLE
});

export const set1DisclaimerIsVisible = isVisible => ({
  type: actionTypes.SET_DISCLAIMER_IS_VISIBLE,
  payload: isVisible
});

export const setTaxYearError = error => ({
  type: actionTypes.SET_TAX_YEAR_ERROR,
  payload: error
});

export const changeReceiptModalEmail = val => ({
  type: actionTypes.CHANGE_RECEIPT_MODAL_EMAIL,
  payload: val
});

export const setIrsNotRespAcknowledgement = prompt => ({
  type: actionTypes.SET_IRS_NOT_RESP_ACKOWLEDGEMENT,
  payload: prompt
});

export const setStagedScheduleOne = val => ({
  type: actionTypes.SET_STAGED_SCHEDULE_ONE,
  payload: val
});

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  payload: redirect
});

export const setAckCopiedVehiclesModalIsDismissed = isDismissed => ({
  type: actionTypes.SET_ACK_COPIED_VEHICLES_MODAL_IS_DISMISSED,
  payload: isDismissed
});

export const togglePaymentAgreementIsOpen = () => ({
  type: actionTypes.TOGGLE_PAYMENT_AGREEMENT_IS_OPEN
});

export const setPaymentAgreementIsOpen = isOpen => ({
  type: actionTypes.SET_PAYMENT_AGREEMENT_IS_OPEN,
  payload: isOpen
});
