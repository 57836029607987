import { destroy } from "redux-form";
import * as actionTypes from "./paymentModal.actionTypes";
import doAsync from "../doAsync";
import { PAYMENT_METHOD_FORM_NAME } from "../order/order.constants";
import { bankAckStep, ccAckStep, mailAckStep, notStartedStep, previousYearStep } from "./paymentModal.constants";
import { getDialogStep, getPaymentMethod, isFilingPreviousYear,getTotalTax, getBankAccountValid, getPaymentAccount } from "./paymentModal.selectors";
import { BANK, CC, MAIL } from "modules/constants";
import { getOrderId } from "modules/utilities/url";

export const toggleDialog = () => ({
  type: actionTypes.TOGGLE_DIALOG
});

export const toggleThankYouDialog = () => ({
  type: actionTypes.TOGGLE_THANK_YOU_DIALOG
});

export const toggleWarningDialog = () => ({
  type: actionTypes.TOGGLE_WARNING_DIALOG
});

export const setPaymentDialog = (dialogOpenName) => ({
  type: actionTypes.OPEN_DIALOG_NAME,
  dialogOpenName
});

const requestStripeToken = () => ({
  type: actionTypes.REQUEST_STRIPE_TOKEN
});

export const makePayment = (stripe, payload) => dispatch => {
  dispatch(requestStripeToken());
  stripe
    .createToken({
      name: "Name",
      address_line1: payload.CcHolderAddress,
      address_city: payload.CcHolderCity,
      address_state: payload.CcHolderState,
      address_zip: payload.CcHolderZip,
      address_country: "US"
    })
    .then(result => {
      if (result.error !== undefined) return dispatch(showError(result.error));
      return dispatch(finishPayment(result.token.id, payload));
    })
    .then(() => dispatch(destroy(PAYMENT_METHOD_FORM_NAME)));
};

export const CallGoogleAnalytics = event => {
  if (typeof window["gtag"] === "function") {
    try {
      const queryParams = {};
      for (let key in localStorage) {
        if (localStorage.hasOwnProperty(key)) {
          queryParams[key] = localStorage.getItem(key);
        }
      }
      const { name: eventName, ...eventData } = event;

      Object.keys(queryParams).forEach(key => {
        eventData[key] = queryParams[key];
      });

      window.gtag("event", eventName, eventData);
      if (Math.random() >= 0.95)
        CallAppInsights({ call: "CallGoogleAnalytics", freq: "0.95", event });
    } catch (err) {
      CallAppInsights({
        call: "CallGoogleAnalytics",
        freq: "0.95",
        error: err,
        event
      });
      console.log(err);
    }
  }
};

export const CallAppInsights = event => {
  if (typeof window["appInsights"] === "object") {
    try {
      window.appInsights.trackEvent({
        name: "GoogleAnalytics",
        properties: event
      });
    } catch (err) {
      console.log(err);
    }
  }
};

export const finishPayment = (Token, payload) =>
  doAsync({
    actionType: actionTypes.STRIPE_PROCESSING_ASYNC,
    url: "pay",
    httpMethod: "post",
    httpConfig: {
      body: JSON.stringify({
        ...payload,
        Token
      }),
      headers: {
        ['wait-lock-key']: Token
      }
    },
    mapResponseToPayload: () => ({}),
    noBusySpinner: true
  });

export const showError = error => ({
  ...error,
  type: actionTypes.SHOW_ERROR
});

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  payload: redirect
});

/** @param {number} [orderId] @param {any[]} [actions] 
*/ //{ AccountNumber = "1122332211", Rtn = "113008465" }
export const fetchVerifyBank = (orderId, actions) => {
  const url = `orders/${orderId}/verify-bank`;
  return dispatch => dispatch(
    doAsync({
      actionType: actionTypes.FETCH_VERIFY_BANK_ASYNC,
      url,
      httpMethod: "get"
    })
  ).then(() => dispatch(startPayment(actions)));
};


const dispatchActions = (dispatch) => (actions) => Promise.all([(actions || []).map(dispatch)])

export const startPayment = (actions) => {
  const _actions = Array.isArray(actions) ? actions : [];

  return function (dispatch, getState) {
    const dispatchAll = dispatchActions(dispatch);

    const state = getState();
    const currentOpenDialog = getDialogStep(state);
    const {fullBankAccount, bankValidated} = getBankAccountValid(state);
    const {Rtn, AccountNumber} = getPaymentAccount(state)

    const priorYear = isFilingPreviousYear(state);
    const paymentMethod = getPaymentMethod(state);
    const totalTax = getTotalTax(state);
    const orderId = getOrderId();

    const priorYearActions = [..._actions, toggleWarningDialog()];
    const ccAckActions = [..._actions, setPaymentDialog(ccAckStep)];
    const mailAckActions = [..._actions, setPaymentDialog(mailAckStep)];
    const bankAckActions = [..._actions, setPaymentDialog(bankAckStep)];
    const stripeDialogActions = [..._actions, toggleDialog()];

    const paymentCheck = () => {
      if (paymentMethod === CC && totalTax > 0)
        return dispatchAll([...ccAckActions]);
      if (paymentMethod === MAIL && totalTax > 0)
        return dispatchAll([...mailAckActions]);
      if (paymentMethod === BANK && totalTax > 0){
        // if valid do the same as above
        if (!bankValidated && fullBankAccount === `${Rtn}${AccountNumber}`)
          return dispatchAll([...bankAckActions]);
        // otherwise validate async
        else if (fullBankAccount !== `${Rtn}${AccountNumber}` && fullBankAccount !== 'error')
          return dispatchAll([fetchVerifyBank(orderId, actions)]);
      }

      return dispatchAll([...stripeDialogActions]);
    }

    // START
    if (currentOpenDialog === notStartedStep){
      if (priorYear)
        return dispatchAll(priorYearActions);

      return paymentCheck();
    }

    // PRIOR YEAR
    if (currentOpenDialog === previousYearStep){
      return paymentCheck();
    }

    // Acknowledge payment rules
    if ([ccAckStep, mailAckStep, bankAckStep].includes(currentOpenDialog)){
      return dispatchAll([...stripeDialogActions]);
    }       
  }
}

